import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Screen(props) {
    // viewLastChanged(state){
    //     // state: show   -> .last-changed.opacity: 1
    //     // state: else   -> .last-changed.opacity: 0
    //     console.log('asd');
    // }

    let cardPosition = {};
    if ( props.viewType === 'card' ) {
        cardPosition = { top: 500 + props.position.top + 'px', left: props.position.left + 'px' }
    };
    return (
        <Fragment>
            <div className="screen" style={cardPosition}>
                <div className="code">{ props.code }</div>
                <div className="update-count">
                    <div className="changed">{ Object(props.view).length == null ? '' : `변경 ${Object(props.view).length} VER`}</div>
                    <div className="last-changed">Last Updated:
                        <div className="last-changed__date">{ Object(props.view).length == null ? '' : props.view[0].date }</div>
                    </div>
                </div>
                <div className="title">{ props.page }</div>
                <div className="info">          
                    <div className="component">{ props.component }</div>
                    <div className="router">{ props.router }</div>
                </div>

                { props.view && ( 
                    <Link to={ `${process.env.PUBLIC_URL}/detail/${props.index}` }>                                
                        <div className="thumb">
                            <div className="slide">
                                <img src={ `/images/${props.view[0].path}` } alt="pages"/>
                            </div>
                        </div>
                    </Link>
                )}

                <div className="progress">
                    <ul>
                        <li className={`design ${ props.isFininshed.design ? 'active' : 'inactive'}`}>
                            <span className="symbol"></span>
                            <span className="text">디자인</span>
                        </li>
                        <li className={`publish ${ props.isFininshed.publish ? 'active' : 'inactive'}`}>
                            <span className="symbol"></span>
                            <span className="text">퍼블리싱</span>
                        </li>
                        <li className={`development ${ props.isFininshed.development ? 'active' : 'inactive'}`}>
                            <span className="symbol"></span>
                            <span className="text">개발</span>
                        </li>
                        <li className={`android ${ props.isFininshed.android ? 'active' : 'inactive'}`}>
                            <span className="symbol"></span>
                            <span className="text">Android</span>
                        </li>
                        <li className={`ios ${ props.isFininshed.ios ? 'active' : 'inactive'}`}>
                            <span className="symbol"></span>
                            <span className="text">iOS</span>
                        </li>
                    </ul>
                </div>
            </div>
      </Fragment>
    );
  }  
  export default Screen;
  