import React, { Fragment } from 'react';

const Page404 = () => {
    return (
        <Fragment>
            Page404
        </Fragment>
    );
};

export default Page404;
