import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';

import Slider from "react-slick";
import data from '../data/data.json?v=20210506';

class Detail extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            idx : this.props.match.params.id,
            selectedDevice : 'iPhone6-7-8'
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);
      }
    _handleKeyDown = (event) => {
        switch( event.keyCode ) {
            case 37: this.changePage('prev'); break;    
            case 39: this.changePage('next'); break;
            case 72: this.props.history.push('/'); break;
            default: break;
        }
    }
    changePage(action) {
        if (action === 'prev') {
            if (this.state.idx === 0) {
                this.setState( { idx : data.length - 1 } );
            } else {
                this.setState( { idx : Number(this.state.idx) - 1 } );
            }
        } else {
            if (this.state.idx === data.length - 1) {
                this.setState( { idx : 0 } );
            } else {
                this.setState( { idx : Number(this.state.idx) + 1 } );
            }
        }
    }

    changeDevice(device) {
        this.setState( { selectedDevice : device} );
    }

    render() {     
        // const settings = {
        //     dots: false,
        //     infinite: true,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     beforeChange: function(currentSlide, nextSlide) {
        //     },
        //     afterChange: function(currentSlide) {
        //     }
        // };  
        const settings = {
            customPaging: function(i) {
              return (
                <a>
                  <img src={ `/images/${data[3].view[0].path}` } alt="pages"/>
                </a>
              );
            },
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          }; 
        return(
            <Fragment>
                <Loader />
                <div className="header">


                    <div className="viewport-wrap">
                        <dl className="viewport-option">
                            <dt>{ this.state.selectedDevice }</dt>
                            <dd><a onClick={ () => this.changeDevice('MotoG4') } href="#">Moto G4</a></dd>
                            <dd><a onClick={ () => this.changeDevice('GalaxyS5') } href="#">갤럭시 S5</a></dd>
                            <dd><a onClick={ () => this.changeDevice('Pixel2') } href="#">Pixel 2</a></dd>
                            <dd><a onClick={ () => this.changeDevice('iPhone5-SE') } href="#">아이폰 5/SE</a></dd>
                            <dd><a onClick={ () => this.changeDevice('iPhone6-7-8') } href="#">아이폰 6/7/8</a></dd>
                            <dd><a onClick={ () => this.changeDevice('iPhone6-7-8Plus') } href="#">아이폰6/7/8 Plus</a></dd>
                            <dd><a onClick={ () => this.changeDevice('iPhoneX') } href="#">아이폰 X</a></dd>
                        </dl>
                    </div>

                    <div className="btn-view">
                        <div onClick={ () => this.changePage('prev') }>
                            <span>이전</span>
                            <b className="shortcut">[ &lt; ]</b>
                        </div>
                        <div>
                            <Link to={ `${process.env.PUBLIC_URL}` }>                                
                                <span>홈으로 이동</span>
                                <b className="shortcut">[ H ]</b>
                            </Link>
                        </div>
                        <div onClick={ () => this.changePage('prev') }>
                            <span>다음</span>
                            <b className="shortcut">[ &gt; ]</b>
                        </div>
                    </div>

                </div>
                <div className="header-space"></div>


                {/* { this.state.idx } */}
                {/* <div className="idx-wrap">{ this.state.idx }/{ data.length }</div> */}

                {/* {
                    "code"        : "SC01",
                    "page"        : "스플래시",
                    "router"      : "",
                    "component"   : "",
                    "position"    : { "top" : 0, "left" : 0 },
                    "isFininshed" : {
                        "design"      : true,
                        "publish"     : true,
                        "development" : true,
                        "android"     : true,
                        "ios"         : true
                    },
                    "view" : [
                        { "path" : "SC01/02-2021-01-17.png", "date" : "2021-01-17" },
                        { "path" : "SC01/01-2021-01-15.jpg", "date" : "2021-01-15" }
                    ]
                }*/}



                <div>
                    <div className="wrap-detail">
                        <h1>{ data[this.state.idx].code }</h1>
                        <h1>{ data[this.state.idx].page }</h1>
                        <h1>{ data[this.state.idx].component }</h1>
                        <h1>{ data[this.state.idx].router }</h1>

                        <div className={`device ${ this.state.selectedDevice }`}>
                            { data[this.state.idx].view && ( 
                                <img className="screen" src={ `/images/${data[this.state.idx].view[0].path}` } alt="view"/>
                            )}
                        </div>

                        <div className="drafts">
                            { data[this.state.idx].view && (                             
                                data[this.state.idx].view.map((row, index) => {
                                    // alert(data[this.state.idx].view);
                                    return (
                                    <div key={index} className="slide">
                                        <img src={ `/images/${row.path}` } alt="pages"/>
                                        <h6>{ row.date }</h6>
                                    </div>
                                    )
                                })
                            )}
                        </div>
                        

                        {/* { data[this.state.idx].view && (                             
                           <Slider className="thumb" {...settings}>
                                {
                                    data[this.state.idx].view.map((row, index) => {
                                        return (
                                        <div key={index} className="slide">
                                            <img src={ `/images/${row.path}` } alt="pages"/>
                                        </div>
                                        )
                                    })
                                }
                            </Slider>
                        )} */}
                        
                    </div>
                    
                </div>
                <div className="page-wrap">{ parseInt(this.state.idx) + 1 }/{ data.length }</div>


            </Fragment>
        )
    }
}

export default Detail;
