import React, { Component } from 'react';
import Loader from '../components/Loader';

import Screen from '../components/Screen';
import data from '../data/data.json';
import Slider from "react-slick";

class Flowchart extends Component {    
  constructor(props) {
      super(props);
      this.state = {
        selectedViewType : 'card',
        selectedAll : true,        
        selectedSection : 'all',
        selectedNone : true,
        selectedDesign : true,
        selectedPublish : true,
        selectedDevelopment : true,
        selectedAndroid : true,
        selectedIos : true,
      }
  }
  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
  }
  _handleKeyDown = (event) => {
      switch( event.keyCode ) {
          case 49: this.selectViewType('card'); break;
          case 50: this.selectViewType('list'); break;  
          case 81: this.selectSection('all'); break;
          case 87: this.selectSection('none'); break;  
          case 69: this.selectSection('design'); break;    
          case 65: this.selectSection('publish'); break;     
          case 83: this.selectSection('development'); break;
          case 68: this.selectSection('android'); break;
          case 70: this.selectSection('ios'); break;
          default: break;
      }
  }

  selectViewType(view) {
    this.setState({ 
      selectedViewType : view
    });
  }
  selectSection(section) {
    if (section === 'all') {
      this.setState({ 
        selectedAll : true,
        selectedSection : 'all',
        selectedNone : true,
        selectedDesign : true,
        selectedPublish : true,
        selectedDevelopment : true,
        selectedAndroid : true,
        selectedIos : true,
      });
    } else {
      this.setState({ selectedAll : false });
      switch (section) {
        case 'none' : this.setState({ selectedNone : !this.state.selectedNone, selectedSection : 'none' }); break;
        case 'design' : this.setState({ selectedDesign : !this.state.selectedDesign, selectedSection : 'design' }); break;
        case 'publish' : this.setState({ selectedPublish : !this.state.selectedPublish, selectedSection : 'publish' }); break;
        case 'development' : this.setState({ selectedDevelopment : !this.state.selectedDevelopment, selectedSection : 'development' }); break;
        case 'android' : this.setState({ selectedAndroid : !this.state.selectedAndroid, selectedSection : 'android' }); break;
        case 'ios' : this.setState({ selectedIos : !this.state.selectedIos, selectedSection : 'ios' }); break;
        default : break;
      }
    }
  }
  checkExpose(row) {
    if (this.state.selectedSection === 'none') {
      if (row.isFininshed.design) { return false } else { return true };
    } else {
      switch (this.state.selectedSection) {
        case 'design' : if (row.isFininshed.design) { return true } else { return false };
        case 'publish' : if (row.isFininshed.publish) { return true } else { return false };
        case 'development' : if (row.isFininshed.development) { return true } else { return false };
        case 'android' : if (row.isFininshed.android) { return true } else { return false };
        case 'ios' : if (row.isFininshed.ios) { return true } else { return false };
        default : break;
      }
    }
  }
  render() {
      return(
        <div className="App">
            <Loader />
            <div className="header">
              <div className="btn-view">
                <div className={`${ this.state.selectedViewType === 'card' ? 'active' : ''}`} onClick={ () => this.selectViewType('card') }>
                  <span>카드뷰</span>
                  <b className="shortcut">[ 1 ]</b>
                </div>
                <div className={`${ this.state.selectedViewType === 'list' ? 'active' : ''}`} onClick={ () => this.selectViewType('list') }>
                  <span>리스트뷰</span>
                  <b className="shortcut">[ 2 ]</b>
                </div>
              </div>
              <div className="progress">
                    <div className="section">
                        <ul>
                          <li 
                              className={`all ${ this.state.selectedAll ? 'active' : 'inactive'}`}
                              onClick={ () => this.selectSection('all') }
                          >
                              <div>
                                <span className="symbol"></span>
                                <span className="text">전체</span>
                              </div>
                              <b className="shortcut">[ Q ]</b>
                          </li>
                          <li 
                              className={`none ${ this.state.selectedSection === 'none' ? 'active' : 'inactive'}`}
                              onClick={ () => this.selectSection('none') }
                          >
                              <div>
                                <span className="symbol"></span>
                                <span className="text">미결정</span>
                              </div>
                              <b className="shortcut">[ W ]</b>
                          </li>   
                          <li 
                              className={`design ${ this.state.selectedSection === 'design' ? 'active' : 'inactive'}`}
                              onClick={ () => this.selectSection('design') }
                          >
                              <div>
                                <span className="symbol"></span>
                                <span className="text">디자인</span>
                              </div>
                              <b className="shortcut">[ E ]</b>
                          </li>
                          <li 
                              className={`publish ${ this.state.selectedSection === 'publish' ? 'active' : 'inactive'}`}
                              onClick={ () => this.selectSection('publish') }
                          >
                              <div>
                                <span className="symbol"></span>
                                <span className="text">퍼블리싱</span>
                              </div>
                              <b className="shortcut">[ A ]</b>
                          </li>
                          <li 
                              className={`development ${ this.state.selectedSection === 'development' ? 'active' : 'inactive'}`}
                              onClick={ () => this.selectSection('development') }
                          >
                              <div>
                                <span className="symbol"></span>
                                <span className="text">개발</span>
                              </div>
                              <b className="shortcut">[ S ]</b>
                          </li>
                          <li
                              className={`android ${ this.state.selectedSection === 'android' ? 'active' : 'inactive'}`}
                              onClick={ () => this.selectSection('android') }
                          >
                              <div>
                                <span className="symbol"></span>
                                <span className="text">Android</span>
                              </div>
                              <b className="shortcut">[ D ]</b>
                          </li>
                          <li
                              className={`ios ${ this.state.selectedSection === 'ios' ? 'active' : 'inactive'}`}
                              onClick={ () => this.selectSection('ios') }
                          >
                              <div>
                                <span className="symbol"></span>
                                <span className="text">iOS</span>
                              </div>
                              <b className="shortcut">[ F ]</b>
                          </li>
                      </ul>
                    </div>
                    
                    
                </div>
              <div className="update">
                <span>마지막 업데이트: </span>
                <time dateTime="2021-06-29">21.06.29</time>
              </div>
            </div>
            <div className="header-space"></div>
    
            <div className="wrap-container">
              <div className={`wrap-screen ${ this.state.selectedViewType === 'card' ? 'screen-card' : 'screen-list'}`}>
                  {
                    data.map((row, index) => {
                        if ( this.state.selectedAll ) {
                          return (
                            <Screen                              
                              key={ row.code }
                              {...row}
                              index={index}
                              viewType={ this.state.selectedViewType }
                            />
                          )
                        } else if ( this.checkExpose(row) ) {
                          return (
                            <Screen
                              key={row.code}
                              {...row}
                              index={index}
                              viewType={ this.state.selectedViewType }
                            />
                          )
                        }
                        
                    })
                  }
              </div>
            </div>
        </div>
      )
  }
}

export default Flowchart;