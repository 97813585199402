import React, { Component, Fragment } from 'react';
import Logo from '../assets/images/logo.svg';

class Loader extends Component {    
    performTimeConsumingTask = async () => {
        return new Promise( ( resolve ) =>
            setTimeout(
                () => {
                    resolve( 'result' )
                },
                500
            )
        )
    };
    async componentDidMount() {
        const data  = await this.performTimeConsumingTask();
        var loader = document.getElementsByClassName( 'loader' );

        if ( data !== null ) {
            loader[0].classList.add( 'animated' );
            loader[0].classList.add( 'slideOutRight' );
        }
    }

    render() {
        return(
            <Fragment>
                <div className="loader">
                    <img src={ Logo } alt="logo"/>
                </div>
            </Fragment>
        )
    }
}

export default Loader;