// Dependencies
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Exception
import Page404 from './pages/Page404';

// Pages
import Flowchart from './pages/Flowchart';
import Detail from './pages/Detail';
class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path={ `${ process.env.PUBLIC_URL + "/" }` } component={ Flowchart } />
                    <Route exact path={ `${ process.env.PUBLIC_URL + "/detail/:id" }` } component={ Detail } />
                    <Route exact component={ Page404 }/>
                </Switch>
            </Router>
        )
    }
}

export default App;
